<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="false"
			:actions="actions"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
//nuevo mixin similar al nativo. Problemas al cargar un mixin en vue.
// Mejor en JS
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import OrganizationActions from './PmOrganizationActions';

import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmorganizationgrid',
	data() {
		return {
			modelName: 'pmorganization',
			actions: [],
			columnDefs: {
				usesatelliteimages: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				shownotifications: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				showaqi: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				showexternallayers: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				usesatelliteimagescomparemap: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				showgeojsonlayers: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				city: true,
				country: true,
				url: true,
				swlat: true,
				swlon: true,
				nelat: true,
				nelon: true,
				timezonename: true,
				acronymdateformat: true,
				subscriptionname: true,
				usesatelliteimages: true,
				shownotifications: true,
				showaqi: true,
				showexternallayers: true,
				showgeojsonlayers: true,
				token: true,
				disabled: true
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		} else {
			this.actions = OrganizationActions.actions;
		}
	},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
